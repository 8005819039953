$active-color: rgba(22, 32, 42, 0.15);

// custom scss
.display-flex {
	display: flex;
}
.relative {
	position: relative !important;
}
.unset-flex {
	flex: unset;
}
.block {
	display: block;
}
.cursor {
	cursor: pointer !important;
}
.modal {
	&.react-draggable {
		z-index: 2060 !important;
	}
}
a {
	&.no-cursor {
		cursor: default !important;
	}
}
.center-button {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.center-buttons {
	display: flex;
	justify-content: center;
	flex-direction: row;
}
.splash {
	&.loading {
		background: #1e2835;
		width: 100%;
		overflow-x: hidden;
		overflow-y: hidden;
	}

	.bar {
		position: relative;
		height: 2px;
		width: 500px;
		margin: 0 auto;
		background: #fff;
		margin-top: 150px;

		@media (max-width: 766px) {
			width: 90%;
		}
	}
	.circle {
		position: absolute;
		top: -30px;
		margin-left: -30px;
		height: 60px;
		width: 60px;
		left: 0;
		background: #fff;
		border-radius: 30%;
		animation: move 5s infinite;
		-webkit-animation: move 5s infinite;
	}
	p {
		position: absolute;
		top: -20px;
		right: -85px;
		text-transform: uppercase;
		color: #1e2835;
		font-family: helvetica, sans-serif;
		font-weight: bold;
	}
	@keyframes move {
		0% {
			left: 0;
		}
		50% {
			left: 100%;
			-webkit-transform: rotate(450deg);
			width: 150px;
			height: 150px;
		}
		75% {
			left: 100%;
			-webkit-transform: rotate(450deg);
			width: 150px;
			height: 150px;
		}
		100% {
			right: 100%;
		}
	}
	@-webkit-keyframes move {
		0% {
			left: 0;
		}
		50% {
			left: 100%;
			-webkit-transform: rotate(450deg);
			width: 150px;
			height: 150px;
		}
		75% {
			left: 100%;
			-webkit-transform: rotate(450deg);
			width: 150px;
			height: 150px;
		}
		100% {
			right: 100%;
		}
	}
}

.error-label {
	color: #b94a48;
}
.form-control {
	&:focus {
		outline: 0 !important;
	}
}
.close-modal {
	position: absolute;
	top: 15px;
	right: 20px;
	float: none;
	z-index: 999;
	color: #111;
	opacity: 1;
	text-shadow: none;
	&.has-drag {
		top: 40px;
	}

	.fa {
		font-size: 12px;
	}
	span {
		display: inline-block;
		vertical-align: middle;
	}
}
.drag-modal {
	cursor: move !important;
	border: 0 none;
	position: absolute;
	right: 1rem;
	top: 1rem;
}
.alert-custom {
	border-radius: 0;
	margin-bottom: 0;
	padding: 0.35rem 1.25rem;
	width: 100%;
}
.md-btn {
	padding: 2px 6px;
}
.item-close {
	border-radius: 50%;
	margin-top: 12px;
	height: 24px;
	width: 24px;
	overflow: hidden;

	span {
		display: block;
		text-align: center;
		font-size: 0.82rem;
		line-height: 24px;
	}
}
.notify-link {
	padding: 12px 0 0;
}
.text-date {
	font-size: 0.4rem;
}
.doctor-block {
	display: flex;
	flex-wrap: wrap;
}
.doctor-list {
	display: flex;
	flex: 1 1 33%; /*grow | shrink | basis */
	border: 1px #eee solid;
	padding: 12px;
	max-width: calc(50% - 16px);
	margin-bottom: 8px;
	margin-right: 8px;

	.doctor-avatar {
		display: flex;
		flex: 0.2;
		justify-content: center;
		align-items: center;
		margin-right: 12px;
	}

	.doctor-name {
		flex: 0.6;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 10;
		font-weight: 700;
	}

	.select-doctor {
		flex: 0.2;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.list-group.complaints {
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;

	a {
		background-color: #f3f6f9;
		margin-right: 6px;

		&:hover {
			background-color: #fff;
		}
	}
}
.ant-popover {
	z-index: 1999 !important;
}

.complain-box {
	width: 480px;
}
.navbar-brand {
	img {
		max-height: 4.5rem;
	}
}
.auth-block {
	width: 768px;

	@media (max-width: 766px) {
		width: 100%;
	}

	.w-xl {
		@media (max-width: 766px) {
			width: 100%;
		}
	}

	.login {
		margin-top: 80px;

		@media (max-width: 766px) {
			margin-top: 20px;
		}
	}
}
.auth-image {
	padding: 0;

	@media (max-width: 766px) {
		width: 90%;
		margin: 0 auto;
	}

	img {
		max-width: 100%;
	}
}
.dropdown-menu {
	.list-item {
		&:first-child {
			padding-top: 0.75rem;
		}

		padding: 0.35rem 0.5rem;
	}
	.item-except {
		line-height: 0.9rem;
	}
}
.alert {
	padding: 0.25rem 1.25rem;
	border-radius: 0;
	border-right-width: 0;
	border-left-width: 0;
	margin-bottom: 12px;
}
.working {
	text-align: center;
	padding: 30px 50px;
}
.order {
	.fa-shopping-cart {
		margin: 8px 0 0;
		font-size: 18px;
	}
}
.separator {
	border-left: 1px solid #d5d5d6;
	height: 280px;
	margin-top: 32px;

	@media (max-width: 766px) {
		display: none;
	}
}

.consultation-room {
	.modal-content {
		position: relative;
	}

	.working {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.box {
		display: flex;

		.video-block {
			position: relative;
		}

		.profile-block {
			padding: 50px 30px 10px;
		}
	}

	video {
		position: absolute;
		background-color: #000;
	}

	#localVideo, #peerLocalVideo {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&.connected {
			top: auto;
			left: auto;
			bottom: 1.2rem;
			right: 1.2rem;
			width: 20%;
			height: 20%;
		}
	}

	#peerVideo1, #peerVideo2 {
		width: 100%;
		height: 100%;
	}

	.tab-content {
		background-color: #f3f6f9 !important;
		border: 1px solid #dee2e6;
		border-top: 0 none !important;
	}

	.history-block {
		.box {
			flex-direction: column;
		}

		.box-body {
			h6 {
				font-size: 0.9rem;
			}
		}
	}
}

.video-control {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: absolute;
	bottom: 1rem;
	left: 0;
	right: 0;
	margin: auto;

	.md-btn {
		margin: 0 6px;
	}
}

@keyframes shake {
	from {
		transform: rotate(10deg);
	}
	to {
		transform-origin: center center;
		transform: rotate(-10deg);
	}
}

.call-waiting {
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	text-align: center;
	margin: auto;
	width: 288px;
	z-index: 1000;

	i {
		animation: shake 0.1s ease-in-out 0.1s infinite alternate;
	}
}
.footer-logo {
	img {
		max-width: 100%;
	}
}

.transactions {
	.item-title {
		cursor: default !important;
	}
}

.pharma-block {
	width: 35%;
	padding: 12px;

	.order {
		height: calc(90vh - 224px);
		overflow-y: scroll;
	}
}

.lab-block {
	width: 35%;
	padding: 12px;

	.order {
		height: calc(90vh - 224px);
		overflow-y: scroll;
	}
}

.report-block {
	width: 30%;
	padding: 12px;
	height: calc(90vh - 130px);
	overflow-y: scroll;

	.prescriptions {
		background-color: #f9fafc;
		padding: 8px;

		.badge {
			background-color: #fff;
			padding: 4px;
			font-size: 0.75rem;

			i {
				cursor: pointer;
			}
		}
	}
}

.accept-cookie {
	color: #fff;
	font-weight: 400;
	font-size: 15px;
	position: fixed;
	bottom: 0;
	z-index: 9999;
	display: flex;
	overflow: hidden;
	padding: 12px 45px;
	justify-content: space-between;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9000;
		background-color: rgba($color: #000000, $alpha: 0.2);
	}

	p {
		line-height: 33px;
		text-align: left;
		color: #1e2835;
		margin: 0 32px 0 0;
		z-index: 9900;
	}

	.accept-link {
		z-index: 9900;

		a {
			cursor: pointer;
			text-decoration: none;
			font-weight: bold;
			background-color: #1e2835;
			color: #fff;
		}
	}
}
.fa-bigger {
	font-size: 14px;
}
.my-list-item {
	list-style-type: none;
	position: relative;
	&:last-child .list-body:after {
		border: none;
	}
	&.active {
		background-color: $active-color;
	}
}

.md-btn.text-small {
	padding: 4px 16px;
	font-size: 11px;

	.fa {
		font-size: 11px;
	}
}

.pagination-center {
	position: relative;
	justify-content: flex-end;
}

.ant-pagination-total-text {
	position: absolute;
	left: 0;
	top: 0;
}

.custom-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000000;
	z-index: 0;
	display: none;

	&.fade {
		opacity: 0;
	}
}
.modal-open {
	.custom-backdrop {
		&.fade {
			opacity: 0.4 !important;
			z-index: 1800 !important;
			display: block !important;
		}
	}
}

.modal {
	z-index: 1900 !important;
	padding-right: 15px;
}

.modal-header {
	border-bottom: 0 none;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;

	&.smaller {
		font-size: 0.99rem;
	}

	&.faded {
		background-color: rgba(0, 0, 0, 0.05);
	}

	span {
		color: #999;
		margin-right: 5px;
	}

	.close {
		color: #111;
		float: none;
		opacity: 1;
		position: absolute;
		right: 20px;
		text-shadow: none;
		top: 15px;
		z-index: 1;
		font-size: 1.35rem;
		font-weight: 500;

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.os-icon {
		font-size: 12px;
	}
}

.btn-size {
	height: 1.4rem !important;
	width: 1.375rem;
}

.margin-opposite {
	margin: 0 -15px;
}

.w-40 {
	img {
		width: 40px;
		height: 40px;
	}
}

.w-32 {
	img {
		width: 32px;
		height: 32px;
	}
}

.w-30 {
	width: 30px;
	margin-right: 12px;

	img {
		width: 30px;
		height: 30px;
	}
}

img {
	&.w-96 {
		height: 96px;
	}
}

.react-confirm-alert {
	.btn {
		color: #fff;

		&:hover {
			color: #fff;
		}
	}
}

.list-appointments {
	display: flex;
}

.permissions-block {
	h4 {
		font-weight: 500;
		font-size: 0.81rem;
		color: #3e4b5b !important;
	}
}

.modal-body {
	h5 {
		font-weight: 500;
		font-size: 0.81rem;
		text-align: center;
		text-transform: uppercase;
		text-decoration: underline;
	}

	.posit-top {
		position: absolute;
		right: 12px;
		top: 0;
		width: 100%;
		text-align: right;
	
		.form-group {
			margin-bottom: 0;
		}
	}
}

.text-underline {
	text-decoration: underline !important;
}

.table th, .table td {
	vertical-align: middle;
}